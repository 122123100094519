.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: 480px) {
    width: 480px;
    padding-left: 20px;
    padding-right: 20px;
  }
  @media screen and (min-width: 768px) {
    width: 760px;
  }
  @media screen and (min-width: 1280px) {
    width: 1280px;
  }
}
