
* {
  box-sizing: border-box;
  margin: auto;
}
html,
body {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  margin-top: 0.5rem;
  font-size: 1.7rem;
  line-height: 1.42;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

:focus,
:active {
  outline: none;
}

a:focus,
a:active {
  outline: none;
}

input,
button,
textarea {
  font-family: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited,
a:hover {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

img {
  display: block;
  max-width: 100%;
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: bold;
}

.visually-hidden {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}