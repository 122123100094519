footer {
  background-color: #00468B;
  padding: 2rem;

.container-footer {
  color: var(--color-text-white);
  letter-spacing: 0.05rem;
  @media screen and (min-width: 1280px) {
      display:flex;
      padding: 0;
  }
}
.footer__item-contact {
  font-size: 1.7rem;
  @media screen and (min-width: 1280px) {
    margin-left: 30px;
  }
}
.footer__item-contact-name {
  font-weight: bolder;
}
.footer__item-phone-number,
.footer__item-email {
  color: var(--color-text-white);
  text-decoration: none;
}
.footer__item-phone-number:hover,
.footer__item-email:hover,
.footer__item-phone-number:focus,
.footer__item-email:focus {
  font-size: 1.8rem;
  border-bottom: 0.2rem solid var(--color-text-white);
}
.copyright {
  color: white;
  font-size: small;
  margin-top: 15px;
  text-align: center;
}
  a {
  color:white;
  margin-left:5px;
  margin-right:5px;
}
}
