.partners-header {
  text-align: center;
  padding: 2rem;
}
.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2.8rem;
  img {
    padding: 15px;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    .partners-tablet{
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
    .partners__item-meja {
      margin-bottom: 33px;
    }
    .partners-tablet-second{
      margin-right: 0;
      margin-left: 0;
    }
  }
  @media screen and (min-width: 1280px) {
    flex-direction: row;
    justify-content: center;
    .partners-tablet{
      display:flex;
    }
     .partners-tablet-second{
      display:flex;
    }
  }
}
 .partners__item_lass {
  background-color: #000;
  width: 70%;
 }
