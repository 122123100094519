.section__galery-slider-photos {
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
   li {
     padding: 15px;
     padding-top: 0px;
    }
}
.section__galery-slider-photo-item {
   width: 300px;
   height: auto;
 }

 .section__galery-slider-photo-logo {
  width: 250px;
 }
 .section__galery-slider-photo-item-last {
  margin: auto;
  @media screen and (min-width: 768px) {
    ul {
      display: flex;
      margin-left: 0;
    }
  }
  @media screen and (min-width: 1280px) {
    margin-left: -12px;;
  }
 }
 .section__galery-slider-photo-last {
  // @media screen and (min-width: 768px) {
    //   margin-left: 15px;
  // }
  @media screen and (min-width: 1280px) {
    margin-left: 40px;
  }
  
 }
 .section__galery-slider-photo-lastt {
  @media screen and (min-width: 768px) {
    margin-left: 30px;
  }
  @media screen and (min-width: 1280px) {
    margin-left: -376px;
  }
 }