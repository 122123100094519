.paslaugos {
  background-color: #128FC8;
}

.section__paslaugos-name {
  color: var(--color-text-white);
  text-align: center;
  padding-top: 2rem;
}
.section__paslaugos {
  padding: 2rem;
  padding-top: 0;
}
.section__paslaugos-items {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}
.section__paslaugos-items-last {
  // display: flex;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.section__paslaugos-item {
  color: var(--color-text-white);
 margin: 0;
//  font-weight: bolder;
 @media screen and (min-width: 768px) {
  text-align: center;
  padding: 10px;
}
}