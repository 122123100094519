.main {
  background-color: var(--color-bcg-content);
  // width: 90%;
}
.section__galery-name {
  color: var(--color-general);
  text-align: center;
  padding: 2rem;
}
.art {
  padding: 1rem;
}
.flex-about-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-us {
  margin-bottom: 15px;
}
.about {
  padding-left: 15px;
  // padding-right: 15px;
}
.form-contact {
  display: none;
}
