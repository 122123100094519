.header {
    padding: 15px;
    background-color: #fff;
  @media screen and (min-width: 1280px) {
    padding: 20px;
    position: fixed;
    top:0;
    width: 100%;
  }

}
.logotip {
  margin: 0;
  img {
    width: 150px;
    margin: 0;
    @media screen and (min-width: 480px) {
      max-width: 100%;
    }
    @media screen and (min-width: 768px) {
      // width: 30%;
    }
    @media screen and (min-width: 1280px) {
      // width: 50%;
    }
  }
}
.nav {
  display: flex;
  flex-direction: column;
  color: var(--color-general);
  @media screen and (min-width: 1280px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }

}
.navigation-flex {
  display: flex;
  justify-content: space-between;
  margin: 0px;
  margin-bottom: 10px;
  @media screen and (min-width: 768px) {
    justify-content: space-around;
    align-items: flex-end;
  }
  @media screen and (min-width: 1280px) {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}
.navigation {
  margin: 0;
}
.main-nav {
  display: flex;
  flex-direction: column;
  li {
    margin: 0;
    text-align: end;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    li {
      margin: 10px;
    }
    li:last-child {
      margin-right: 0px;
    }
  }
}
.main-nav-each {
  font-size: 1.7rem;
  color: var(--color-general);
  font-weight: bold;
  text-align: end;
  @media screen and (min-width: 1280px) {
     font-size: 1.8rem;
    }
}
.main-nav-each:hover,
.main-nav-each:focus {
  font-size: 1.9rem;
  color: #128FC8;
  border-bottom: 0.2rem solid #128FC8;
}
.nav__rezer {
  margin: 0;
  // margin-right: 40px;
  text-align: end;
}
.rezer {
  font-size: 1.5rem;
  font-weight: bolder;
  color: var(--color-general);
}
.contact {
  font-size: 1.4rem;
  color: var(--color-general);
}
.contact:hover,
.contact:focus {
  font-size: 1.7rem;
  color: #128FC8;
  border-bottom: 0.2rem solid #128FC8;
}
.spelling-by-letter {
  background-color: #F2D709;
  min-height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 1280px) {
    margin-top: 100px;
  }
}
.spelling-by-letter-name {
  color: white;
  text-align: center;
}

.ml12 {
  @media screen and (min-width: 1280px) {
    font-weight: 200;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    .letter {
      display: inline-block;
      line-height: 1em;
    }
   }
}
.ml123 {
  @media screen and (min-width: 1280px) {
    font-weight: 200;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.5em;
    .letter {
      display: inline-block;
      line-height: 1em;
    }
   }
}
.spelling-by-letter-kainos {
  background-color: #128FC8;
  min-height: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
}