.raketa {
    width: 70px;
    height: 70px;
    border: none;
    background-color: transparent;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 20;
    @media screen and (min-width: 768px) {
        bottom: 15px;
        right: 15px;
    }
    @media screen and (min-width: 1280px) {
        bottom: 20px;
        right: 20px;
    }
}

.raketa-svg {
    position: absolute;
    fill: var(--accent-color);
    width: 30px;
    height: 30px;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    @media screen and (min-width: 768px) {
        width: 40px;
        height: 40px;
    }
    @media screen and (min-width: 1280px) {
        width: 50px;
        height: 50px;
    }
}