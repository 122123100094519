.form-contact {
  border: var(--color-bcg-sbl) solid 0.1rem;
}
.contact-us {
  padding: 1rem;
  margin: 3rem;
}
textarea {
  height: 18rem;
  width: 100%;
}

.button-submit {
  text-align: center;
}
button {
  background-color: var(--color-bcg-sbl);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  color: white;
}
